type PasswordStrengthRequirements = {
  notEmpty: boolean
  noSpaces: boolean
  digit: boolean
  lowercase: boolean
  uppercase: boolean
  specialCharacter: boolean
  minLength: boolean
}

export const PASSWORD_STRENGTH_ERROR_MESSAGES: Record<
  keyof PasswordStrengthRequirements,
  string
> = {
  notEmpty: 'Enter a Password',
  noSpaces: 'Password cannot contain spaces',
  digit: 'Password must contain a number between 0 and 9',
  lowercase: 'Password must contain a lower case letter between a-z',
  uppercase: 'Password must contain an upper case letter between A-Z',
  specialCharacter: 'Password must contain a special character (e.g. $, #, @)',
  minLength:
    'Password must have a minimum length of 12 and maximum length of 256',
}

export class PasswordValidator {
  static getError(password: string): string | undefined {
    const passwordStrengthRequirementsSatisfied =
      PasswordValidator.validatePasswordStrength(password)

    for (const [key, value] of Object.entries(
      passwordStrengthRequirementsSatisfied
    )) {
      if (!value) {
        const errorKey = key as keyof PasswordStrengthRequirements
        return PASSWORD_STRENGTH_ERROR_MESSAGES[errorKey]
      }
    }

    return undefined
  }

  /**
   * @param password The password to validate against
   * @returns An object with fields for each requirement,
   * set to true/false depending on if they are matched.
   */
  private static validatePasswordStrength(
    password: string
  ): PasswordStrengthRequirements {
    const notEmpty = /.+/
    const spaces = /\s/
    const digit = /[0-9]/
    const lowercase = /[a-z]/
    const uppercase = /[A-Z]/
    const specialCharacter = /[*.!#@$%^&(){}[\]:;<>,.?/~_+\-=|]/
    const minLength =
      /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!#@$%^&(){}[\]:;<>,.?/~_+-=|]).{12,256}/

    const result: PasswordStrengthRequirements = {
      notEmpty: Boolean(password.match(notEmpty)),
      noSpaces: Boolean(!password.match(spaces)),
      digit: Boolean(password.match(digit)),
      lowercase: Boolean(password.match(lowercase)),
      uppercase: Boolean(password.match(uppercase)),
      specialCharacter: Boolean(password.match(specialCharacter)),
      minLength: Boolean(password.match(minLength)),
    }

    return result
  }
}
